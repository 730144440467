const zh = {
  connect: "连接",
  cancel: "取\xa0\xa0\xa0消",
  connect_to_metamask: "与Metamask连接",
  change: "改\xa0\xa0\xa0变",
  copy_address: "复制地址",
  view_on_bsccan: "在Bscscan上查看",
  Decentralized_transparent: "去中心化，安全、公开、透明、智能合约链上执行",
  Execution_chain: "所有规则机制公开透明,搭建全新的游戏公正平台",
  All_transparent: "3-4月上线探索副本类已级竟猜类题材游戏，对抗竟技、争夺财富先机",
  Build_platform: "后续不断更新更多新游戏新玩法敬请期待",
  All_categories: "全品类挖矿,开启更多致富通道,流动性挖矿,游戏性挖矿。",
  Gateway_to_your_Wealth: "更多的权益派送给持有LP的玩家",
  Liquidity_mining: "不定期空头专属NFT",
  // Game_mining: "游戏性挖矿",
  Stay_tuned: "工会在游戏里有不同的权益等待所有的玩家。每月工会都有定向的福利等您来拿。",
  game_has_recently:
    "游戏行会工会模式，所有喜爱GWC游戏玩家都可以加入自己的阵营获取工会不同的奖励以及福利!",
  Game_props_are_set_up:
    "游戏道具设立，玩家可以用手中GWC来购买相应道具，增加不同的属性功能获取更好的游戏体验。获得道具的方法只能通过消耗GWC，所有消耗均销毁加速GWC通缩。",
  The_participants: "参与人数",
  Expenditure_exploration: "消耗探索",
  Your_balance: "你的余额",
  Authorization: "授权",
  Authorizations: "授\xa0\xa0\xa0权",
  Waiting_for_the_draw: "副本探索中",
  Kill_the_boss: "击杀Boss",
  View_earnings: "查看收益",
  The_second_LP:
    "GWC游戏第二个是LP-NPC，玩家可以通过SWAP自行配对资产进行支付相关游戏所需参与LP-NPC探索，每组10人次，人数达到时LP-NPC会随机掉落5人，每人获得参与LP数量的150%，没有掉落“装备”的剩余5人会获得相应的GWC代币补偿，参与GWC财神游戏LP-NFT副本探索的玩家100%有奖励。",
  The_rules_follows: "规则如下:",
  Players_pay: "玩家支付1个指定币参与副本，当其中有10名玩家时开启这个副本;",
  Players_participate: "玩家参与副本支付指定币的80%-90%用于开奖，10%-20%归平台所有;",
  Players_participate_lp:"玩家参与副本支付指定币的80%用于开奖，20%归平台所有;",
  Players_participate_in_replica_NPCs:
    "玩家参与副本NPC，探索副本成功NPC会随机把指定币的70％掉落给其中一个玩家，剩余30％注入GWC基金，用于GWC销毁通缩和工会以及GWC社区奖励",
  Among_the_players:
    "在参与副本的玩家中随机选择5人均分开奖币，剩余玩家得到相应代币的补偿。",
  Among_the_players_lp:"在参与副本的玩家中随机选择1人中奖",
  Please_quantity: "请输入有效数量",
  Quantity_cannot: "数量不能为0",
  Insufficient_available: "可用余额不足",
  Pending: "待领取",
  receive: "领\xa0\xa0\xa0取",
  time: "时间",
  successful_kill:"击杀成功",
  Authorization_successful:"授权成功",
  award: "奖励",
  confirm: "确\xa0\xa0\xa0认",
  Available_Balance: "可用余额: ",
  Unavailable_Balance:"未解锁余额: ",
  Pledge: "质押",
  get: "获得",
  choose: "选择",
  Cumulative_pledge: "累计质押",
  established_different:
    "GWC设立不同类型的”B0SS”NPC，NPC可掉落不同的装备。玩家可以通过参与NPC探索副本环节参与游戏，10人到1000人不等（每个“BOSS”有固定的人数参与），当参与人数达成时视为探索成功，立即触发掉落装备环节，少数参与此环节的游戏玩家可以获得“BOSS”随机掉落的例如比特币、以太坊等等相关数字资产。参与游戏的方式只需支付探索副本费用1GWC等相关探索npc费用。",
  Can_be_taken_out: "可取出",
  established_different_lp:"GWC设立不同类型的”B0SS”NPC，NPC可掉落不同的装备。玩家可以通过参与NPC探索副本环节参与游戏，10人到1000人不等（每个“BOSS”有固定的人数参与），当参与人数达成时视为探索成功，立即触发掉落装备环节，少数参与此环节的游戏玩家可以获得“BOSS”随机掉落的例如GWC-USDT等等相关数字资产。参与游戏的方式只需支付探索副本费用1lp等相关探索npc费用。",
  take_out: "取\xa0\xa0\xa0出",
  Pledged: "已质押",
  Pledges: "质\xa0\xa0\xa0押",
  Not_yet_open: "暂未开放",
  Duplicate_kill: "重复击杀副本无效",
  NPC: "NPC",
  successfully_explored: "探索成功",
  Exploration_failure: "探索失败",
  Discovery_failed_replica_compensation: "副本补偿",
  participate: "参\xa0\xa0\xa0与",
  Participated: "已\xa0\xa0参\xa0\xa0与",
  Yield: "收益率",
  Removable: "到期可取出",
  Locked_warehouse: "已锁仓",
  warehouse: "锁\xa0\xa0\xa0仓",
  Invite_rebate: "邀请返利",
  Referrer: "推荐人",
  Add: "添\xa0\xa0\xa0加",
  Please_recommender: "请输入推荐人",
  Introduction_pool: "DAO池介绍",
  Introduction: "1.社区投票治理权限，GWC社区未来将把权重完全移交社区GWC持有者;",
  Need_pool: "2.GWC生态体系收益月分红;",
  After_locking_the_warehouse: "3.享受超高年化收益;",
  You_can_invite_other_addresses: "4.享受生态内合作项目的空投权限;",
  Lower_the_barriers: "5.降低工会进入门槛，提高道具合成爆率。",
  within_the_balance: "请输入余额以内的值",
  value_greater_than: "请输入大于0的值",
  Optional: "选填",
  DAO_permissions: "DAO权限",
  Introductions: "1.本DAO池简介;",
  Need_pools: "2.需要锁仓500GWC或500整数倍的GWC一年，即可参与本DAO池;",
  After_locking_the_warehouses: "3.锁仓到期后可领取200%的锁仓收益;",
  You_can_invite_other_addressess:
    "4.可以邀请其他地址来参与本DAO池，推荐的地址参与DAO池后，推荐人立刻获得锁仓收益的10%。",
  Please_enter_500: "請輸入500的整數倍",
  Audit_Report: "审计报告",
  The_technical:"技术开发团队正在持续更新的游戏环节以及全新的游戏内容，海量游戏等您来。除此之外GWC还有大量的福利定期空投到优质玩家手中，例如原创且有价值的NFT等等GWC拥有着不断探索的精神惊喜持续中",
  game_exploration:"GWC游戏探索未知领域新玩法",
  The_game_is_constantly:"游戏不断更新中",
  New_gameplay:"新玩法 新征程 敬请期待",
  not_yet_open:"暂未开放",
  join_now:"立即参与",
  stay_tuned:"敬请期待",
  The_interstellar1:"星际妖兽携带大量的",
  The_interstellar2:"，所有",
  The_interstellar3:"勇士都可进入星际消除此怪兽，获得掉落的",
  The_interstellar4:"奖励",
  The_interstellar:"星际妖兽携带大量的GWC，所有GWC勇士都可进入星际消除此怪兽，获得掉落的GWC奖励",
  The_interstellar_lp:"星际妖兽携带大量的GWC-USDT LP，所有GWC-USDT LP勇士都可进入星际消除此怪兽，获得掉落的GWC-USDT LP奖励",
  BNB_monsters:"BNB怪兽逃往虫洞，所有BNB勇士均可参与探索虫洞，消灭BNB怪兽获得BNB",
  BNB_monsters_big:"BNB怪兽逃往虫洞，所有BNB勇士均可参与探索虫洞，消灭BNB怪兽精英获得大量BNB",
  A_lang_number:"U星近期出现大量不明生物怪兽，联盟发出悬赏令进入乘坐飞船进入U星消灭生物怪兽获得USDT奖励",
  Et_monsters:"USDT怪兽侵入H星球，星球智慧文明遭遇重创，USDT联盟召集令，消灭USDT怪兽携带大量USDT",
  monster_profile:"怪物简介",
  More_elements:"更多的元素更多的探索",
  Gain_power:"掌控一切获得的力量",
  Explore_all:"探索一切未知的领域",
  The_interstellar_s:"星际妖兽携带少量的GWC，所有GWC勇士都可进入星际消除此怪兽，获得掉落的GWC奖励。BOSS击杀之时会和1名玩家同归于尽，1名玩家失败，10位玩家可获得胜利",
  The_interstellar_s_lp:"星际妖兽携带少量的GWC-USDT LP，所有GWC-USDT LP勇士都可进入星际消除此怪兽，获得掉落的GWC-USDT LP奖励",
  Brand_new_game:"全新游戏升级更新中",
  Con_con_con:"不断的对抗，不断的完善",
  Query_the_fall:"每局不同人物倒落概率查询",
  Different_props_in:"每局不同道具影响结局概率查询",
  Universe_Exploration:"宇宙探索 财富密码",
  More_games_look_forward:"更多游戏 期待你的加入",
  Game0fix:"。BOSS击杀之时会和",
  Game1fix:"名玩家同归于尽，",
  Game2fix:"名玩家失败，",
  Game3fix:"位玩家可获得胜利",
  ranking:"排名",
  Unlock:"解锁倒计时",
  Trade_union:"工会名称",
  union_rewards:"工会奖励",
  lifting:"升降",
  no_yet:"暂无",
  Rewards_are_not:"奖励获得暂无",
  Introduction1:"简介",
  join_a_union:"加入工会",
  GWC_has_set:"GWC设立了工会模式，让更多玩家能够参与到工会到当中找到归属感。成立工会有非常多的权益，每个工会分别设立工会会长一名工会副会长2-5名，工会长老10-20名。",
  There_are_irregular:"每个月都有不定期奖励给到工会由工会会长以及副会长按功劳合理分配给发展壮大工会以及优秀工会玩家。玩家创立工会需质押一定数额GWC，成立工会有丰厚的回报等着您。",
  There_are_also_massive:"玩家参与工会也有海量好处",
  Improve_the_game_explosion:"1、提高游戏爆率以及游戏成功概率",
  The_trade:"2、工会帮扶结实更多志同道合的好朋友",
  Priority_to_obtain:"3、优先获得更多游戏资讯以及进度",
  Assist_the_union:"4、协助工会获得更好的排名获得空投奖励",
  Players_join_the_union:"玩家加入工会要求",
  players_need_to_pay:"1、工会自行设定加入门槛例如0-10GWC玩家需要支付",
  Complete_the_tasks:"2、完成工会相关布置任务",
  Union_introduction:"工会介绍与规则",
  GWC_game_has:"GWC游戏有设置了大量游戏道具",
  Choosing_different_props:"选择不同的道具能够增加一定的游戏属性增设传奇精英等等级别的道具",
  The_purchase_of_each:"每个道具的购买需支付一定额度的GWC不会影响游戏平衡以及打破游戏平衡只会增加游戏收入以及降低游戏门槛部分道具拥有“保本”功能!",
  NFT_characters:"GWC游戏有大量的原创NFT人物",
  game_attributes:"选择不同的人物能够增加一定的游戏属性",
  characters_are_unique:"增设传奇精英等等级别人物，部分人物拥有唯一性!",
  Buy_a_character:"购买人物即可立刻获得一份人物NFT",
  amount_of_GWC:"每个人物的购买需支付一定额度的GWC",
  Does_not_affect:"不会影响游戏平衡，以及打破平衡",
  winer:"获得奖励",
  game2tips:"时空之门突然在GWC宇宙上出现了十个黑洞，每个黑洞都通向不同的星际世界然而，这些星际世界都面临着同样的问题种可怕的怪兽，它们肆虐着整个星系，摧毁着每一颗星球。为了保护时空，摧毁怪兽，各个星际势力派遣了精英勇士前来GWC宇宙。这些勇士必须团结一心，合作击败怪兽。虽然任务十分困难但任务完成后，他们将获得丰厚的奖励。现在，时空之门即将关闭，如果这些勇士不能消灭所有的怪兽，GWC宇宙也将受到严重的威胁。时间紧迫，任务艰巨，究竟能否完成任务呢?",
  game2tips2:"每五分钟从0-9十个黑洞随机出现一个怪兽",
  game2tips3:"所有GWC勇士需购买GWC武器攻击黑洞，如预测成功怪兽从哪个黑洞出现并且消灭，可获得怪兽的奖励。",
  game2tips4:" 预测1个黑洞8倍收益、预测2个黑洞4倍收益、预测4个黑洞2倍收益",
  game2tips5:"规则如下: ",
  game2Info1:"上轮开启的<span>{{num}}</span>号时空之门",
  game2Info3:"距离本期时空之门开启还有<span>{{time}}</span>",
  takepartin:"立即参与",
  viewearnings:"查看收益",
  game2history:"历史时空之门出怪记录",
  game2historymsg:"1号为最新出怪黑洞(时空之门 )，统计历史100次黑洞出怪记录，希望勇士可以取得胜利",
  price:"费用：",
  income:"可获胜收益：{{num}} GWC（{{times}}倍）",
  InsufficientBalance:"余额不足",
  numbererror:"只能选择1、2、4个",
  create:"创建工会",
  Guildrating:"公会星级：",
  GuildName:"公会名称：",
  GuildIntroduction:"公会简介：",
  GuildImages:"公会图片：",
  Costofcreation:"创建费用：",
  createnow:"立即创建",
  rules1:"请选择工会星级",
  rules2:"请输入工会名称",
  rules3:"请输入工会简介",
  rules4:"请上传工会图片",
  Guildrank:"公会排名：",
  GuildMaster:"公会会长：",
  waterflow24h:"24H流水：",
  holdgwc:"持GWC：",
  Guildpeople:"公会成员",
  search:"搜索",
  member:"会员",
  Hflowwater24GWC:"24H流水(GWC)",
  flowwaterGWC:"总流水(GWC)",
  Hflowwater24BTY:"24H流水(USDG)",
  flowwaterBTY:"总流水(USDG)",
  invitednumber:"邀请数",
  Referrersaddress:"推荐人地址（必填）",
  join:"立即加入",
  Referrersaddressempty:"推荐人地址不能为空",
  linkwallet:"请连接钱包",
  joinok:"你已经加入该工会",
  GuildPeopleNumber:"工会人数：",
  GuildStar:"星级:",
  Star:"星",
  Star0:"普通",
  waitgame:"请先等待开奖",
  edit:"编辑工会",
  saveEdit:"保存修改",
  timeeditover:"每30天才可编辑一次公会信息",
  success:"成功",
  hasCreate:"已经创建过工会了",
  isjoined:"已经有工会了",
  between10_2000:"参与费用范围只能在10到2000GWC",
  lab24gwc:'24H流水(GWC):',
  lab24bty:'24H流水(BTY):',
  lab24usdg:'24H流水(USDG)',
  labmonthusdg:'月流水(USDG)',
  labGWc:"总流水(GWC):",
  labBty:"总流水(BTY):",
  labUsdg:"总流水(USDG)",
  d:"天",
  h:'小时',
  m:"分",
  s:"秒",
  Winaprize:"中奖",
  noWinaprize:"未中奖",
  Notdrawn:"未开奖",
  Number:"数字",
  PayGWC:"支付GWC",
  Status:"状态",
  home:{
    about:{
      content1:'**游戏一：单一货币游戏**',
      content2:'GWC精心打造了多款特色各异的“BOSS”型NPC，它们各自代表着不同的理念和态度。玩家可以通过参与NPC探索地下城等多样化的环节来投身游戏，参与人数范围广泛，从10人到1000人不等（每个“BOSS”都设定了固定的参与人数）。一旦达到所需人数，便视为探索成功，并立刻触发装备掉落环节。参与该场游戏的玩家将有机会随机获得由“BOSS”掉落的数字资产，如比特币、以太坊、比特元等。参与游戏的方式简单明了，只需支付1U的探索副本费用及其他相关资产即可。',
      content3:'**游戏二：LP游戏**',
      content4:'LP游戏则更具挑战性，它会在众多参与者中随机选取5人，每人将获得参与LP数量的150%作为奖励，且不存在装备掉落的情况。而未被选中的其余5位玩家，则将在“装备”环节获得不同的收益。',
      content5:'**游戏三：联盟模式**',
      content6:'近期，游戏新增了联盟模式，为所有热爱GWC的玩家提供了更多可能。无论您是喜欢挑战极限的竞技高手，还是乐于结交新朋友的社交达人，都可以在这个模式下找到属于自己的乐趣和舞台。让我们携手共进，共创美好游戏世界！'
    },
    introduce:{
      item1:{
        title:'流动挖矿',
        content1:'在GWC的广阔天地里，我们开启了一场全品类的挖矿盛宴！无论你是数字货币的狂热粉丝，还是区块链技术的探索者，这里都有属于你的宝藏。我们提供多种挖矿方式，满足不同需求的矿工们，让每一次挖掘都充满惊喜。',
        content2:'更令人激动的是，我们还会不定期进行空投奖励活动！只要你积极参与挖矿，就有机会获得丰厚的空投奖励，让你的挖矿之旅更加丰富多彩。'
      },
      item2:{
        title:'关于游戏',
        content1:'GWC运用前沿的加密认证技术和去中心化共识机制，精心打造一个完整、分布式且数据不可篡改的电竞社区。在这里，用户可以尽享沉浸式的竞技体验，无需担忧比赛的公平性与合规性。同时，通过DeFi产品的应用，用户还能够获得丰厚的多重收益，实现竞技与收益的完美结合。',
        // content2:'此外，GWC以竞技币（如GWC BTY等）作为结算代币，将生态项目紧密地联系在一起，有效连接各层级用户，从而构建一个独具匠心的区块链游戏生态。这个生态没有任何泡沫，不仅确保了比赛的公平性，还保证了用户能够长久稳定地参与游戏竞技。随着GWC价值的持续稳定增长，整个生态将愈发丰盈丰满，为用户带来更加美好的竞技体验。'
        content2:'',
      },
      item3:{
        title:'关于部落',
        content1:'游戏部落工会模式，所有喜爱GWC游戏玩家都可以加入自己的阵营 获取工会不同的奖励以及福利！ 工会在游戏里有不同的权益等待所有的玩家。每月工会都有定向的福利 等您来拿。'
      },
    },
    model:{
      title:'ECONOMIC MODEL',
      tips:'PLAY / COLLECT / EARN',
      content:'在GWC的虚拟世界中。玩家不仅通过精心设计的游戏收集策略，积累了丰富的资源和经验，更在组队作战中展现出无与伦比的默契与团队精神。无论是激烈的对抗赛还是复杂的任务挑战，凭借着精准的判断和果断的行动，一次次获得胜利获得更多的GWC奖励。',
      item1:{
        no:'ONE',
        content1:'Monthly updates'
      },
      item2:{
        no:'TWO',
        content1:'GWC ecology'
      },
      item3:{
        no:'THREE',
        content1:'rich playing method'
      },
    },
    last:{
      content1:'技术开发团队正在持续更新的游戏环节以及全新的游戏内容，海量游戏等您来。',
      content2:'除此之外GWC还有大量的福利定期空投到优质玩家手中，例如原创且有价值的NFT等等',
      content3:'GWC拥有着不断探索的精神',
      content4:'惊喜持续中'
    }
  },
  news:{
    homeTitle: "GWC生态最新动态",
    title:"GWC全生态新闻",
    tips:"GWC最新公告",
    button:"修改",
    confirm:"提交修改"
  },
  game3:{
    title:'一期总量8000万BTYY',
    clock:'倒计时',
    days:'天',
    up:'看 涨',
    down:'看 跌',
    selected:'已选择:',
    button:'点击参与',
    t1:'你锁定的BTYY',
    t2:'锁定BTYY的时间',
    t3:'你锁定的BTYY的释放',
    t4:'胜利获得GWC总数量',
    t5:'参与详情',
    d1_title:'GWC猜猜盲盒价值',
    d1_desc1:'GWC精心打造了多款游戏不同的竞技模型，玩家参与猜猜盲盒可以增加GWC生态玩法的同时，降低GWC流通，增加质押。同时BTYY数量达到100W的用户触发NFT功能。可获得GWC专属NFT，NFT海量玩法等你来探索！',
    d1_desc2:'GWC 限量 NFT总量500，拥有社区权限以及道具功能！在生态应用以及应用道具上均有好处。',
    d2_title:'GWCNFT',
    d2_desc:'全球限量GWCNFT 500枚，NFT可在GWC生态游戏板块拥有治理参与投票权限以及道具功能属性，共同打造GWC生态世界。2024年所有应用的均向生态GWCNFT持有玩家以及工会社群开放权限。',
  }
};
export default zh;
