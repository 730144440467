const en = {
  connect: "connect",
  cancel: "Cancel",
  connect_to_metamask: "connect to metamask",
  change: "change",
  copy_address: "copy address",
  view_on_bsccan: "view on bsccan",
  Decentralized_transparent: "Decentralization, security, openness, transparency, smart contract execution on the chain",
  Execution_chain:
    "All rules and mechanisms are open and transparent, building a brand new game fair platform",
  All_transparent: "From March to April, explore dungeon-level and quiz-themed games, fight against competition skills, and compete for wealth opportunities",
  Build_platform: "Stay tuned for more new games and new ways of playing in the follow-up updates",
  All_categories: "Full-category mining, open more channels to get rich, liquidity mining, game mining.",
  Gateway_to_your_Wealth: "More benefits are distributed to players who hold LP",
  Liquidity_mining: "Irregular short exclusive NFT",
  // Game_mining: "Gmae Mining",
  Stay_tuned: "The union has different rights and interests waiting for all players in the game. The monthly union has targeted benefits waiting for you to collect.",
  game_has_recently:
    "In the game guild mode, all players who love GWC games can join their own camp to obtain different rewards and benefits of the guild!",
  Game_props_are_set_up:
    "Game Properties have been set up, players are be able to add extra functions and features by purchase properties, properties are priced in GWC. The only way to get properties is to consume GWC, and all consumption will speed up the deflation of GWC.",
  The_participants: "Number of Participants",
  Expenditure_exploration: "Consumption Exploration",
  Your_balance: "Your Balance",
  Authorization: "Authorization",
  Authorizations: "Authorization",
  Waiting_for_the_draw: "Copy exploring",
  Kill_the_boss: "Kill the boss",
  View_earnings: "View earnings",
  The_second_LP:
    "The second GWC game is LP-NPC. Players can use SWAP to match assets to pay for related games. They need to participate in LP-NPC exploration. There are 10 people in each group. When the number of people is reached, LP-NPC will randomly drop 5 people, and each person will get it. Participating 150% of the number of LPs, the remaining 5 people who did not drop the “equipment” will receive the corresponding GWC token compensation, and 100% of the players who participate in the exploration of the LP-NFT copy of the GWC Fortuna game will be rewarded.",
  The_rules_follows: "The rules are as follows:",
  Players_pay:
    "Players pay 1 designated coin to participate in the dungeon, and open the dungeon when there are 10 players;",
  Players_participate:
    "Players participating in the dungeon pay 80%-90% of the specified currency for the lottery, and 10%-20% belongs to the platform;",
  Players_participate_lp:"Players participating in the dungeon pay 80% of the designated currency for the lottery, and 20% belongs to the platform;",
    Players_participate_in_replica_NPCs:
    "Players participate in replica NPCs. If they succeed in exploring replicas, NPCs will randomly drop 70% of the specified coins to one of them, and the remaining 30% will be injected into GWC fund for GWC's destruction of deflation, trade unions and GWC community rewards.",
  Among_the_players:
    "Among the players participating in the dungeon, 5 players are randomly selected to divide the prizes, and the remaining players will be compensated by the corresponding tokens.",
  Among_the_players_lp:"Randomly select 1 player among the players participating in the copy to win the prize",
    Please_quantity: "Please enter a valid quantity",
  Quantity_cannot: "Quantity cannot be 0",
  Insufficient_available: "Insufficient available balance",
  Pending: "Pending",
  receive: "receive",
  time: "time",
  award: "award",
  confirm: "confirm",
  Available_Balance: "Available Balance ",
  Unavailable_Balance :"Locked Balance",
  Pledge: "Pledge",
  Pledges: "Pledge",
  get: "get",
  choose: "choose",
  Cumulative_pledge: "Cumulative pledge",
  established_different:
    "GWC has several types of Boss NPC, NPC can drop different equipments.  Players can participate in the game by participating in the NPC exploration copy link, Starting from 10 to 1000 people (each ”boss” has a fixed number of participants). When the number of participants reache the requirement, it will automatically execute the “equipment drop“. A few of lucky players can receive digital asstes that randomly dropped by the Boss, such as Bitcoin and Ethereum etc. players only need to pay 1gwc or equivalent crypto to participate the NPC exploration.",
  Can_be_taken_out: "Can be taken out",
  established_different_lp:'GWC sets up different types of "BOSS" NPCs, and NPCs can drop different equipment. Players can participate in the game by participating in the NPC exploration dungeon, ranging from 10 to 1,000 people (each "BOSS" has a fixed number of people to participate), when the number of participants is reached, the exploration is considered successful, and the equipment drop link is immediately triggered, and a small number of participants Players in this session can obtain related digital assets such as GWC-USDT randomly dropped by "BOSS". To participate in the game, you only need to pay the exploration copy fee 1lp and other related exploration NPC fees.',
  take_out: "Take out",
  Pledged: "Pledged",
  Not_yet_open: "Not yet open",
  Duplicate_kill: "Duplicate kill copy is invalid",
  NPC: "NPC",
  successfully_explored: "successfully explored",
  Exploration_failure: "Exploration failure",
  Discovery_failed_replica_compensation: "Replica compensation",
  participate: "Participate",
  Participated: "Participated",
  Yield: "pate of return",
  Removable: "Available",
  Locked_warehouse: "Already lock up",
  warehouse: "Lock up",
  Invite_rebate: "invite rebate",
  Referrer: "Referrer",
  Add: "Add",
  Please_recommender: "Please enter recommender",
  Introduction_pool: "DAO Pond introduce",
  Introduction:
    "1. Community voting governance authority, the GWC community will completely transfer the weight to the community GWC holders in the future;",
  Need_pool: "2. Monthly dividend of GWC ecosystem income;",
  After_locking_the_warehouse: "3. Enjoy ultra-high annual income;",
  You_can_invite_other_addresses:
    "4. Enjoy the airdrop permissions for cooperative projects within the ecosystem;",
  Lower_the_barriers:
    "5. Lower the barriers to union entry and increase the rate of props synthesis explosion.",
  within_the_balance: "Please enter a value within the balance",
  value_greater_than: "Please enter a value greater than 0",
  Optional: "Optional",
  DAO_permissions: "DAO permissions",
  Introductions: "1.Introduction to this DAO pond;",
  Need_pools:
    "2. You need to lock up 500GWC or an integer multiple of 500GWC for one year to participate in this DAO pool; ",
  After_locking_the_warehouses:
    "3.200% of the lock-up income can be received after the lock-up expires; ",
  You_can_invite_other_addressess:
    "4.You can invite other addresses to participate in this DAO pool. After the recommended address participates in the DAO pool, the recommender immediately gets 10% of the lock-up income。",
  Please_enter_500: "Please enter an integer multiple of 500",
  Audit_Report: "Audit Report",
  The_technical:"The technical development team is continuously updating the game links and new game content, and a large number of games are waiting for you. In addition, GWC also has a large number of benefits that are regularly airdropped to high-quality players, such as original and valuable NFT, etc. GWC has The spiritual surprise of continuous exploration continues",
  game_exploration:"GWC game explores new ways to play in unknown areas",
  The_game_is_constantly:"The game is constantly being updated",
  New_gameplay:"New gameplay new journey stay tuned",
  not_yet_open:"not yet open",
  stay_tuned:"stay tuned",
  successful_kill:"successful kill",
  Authorization_successful:"Authorization successful",
  join_now:"join now",
  The_interstellar1:"The interstellar monster carries a large amount of ",
  The_interstellar2:" all ",
  The_interstellar3:" warriors can enter the interstellar to eliminate this monster, and get the dropped ",
  The_interstellar4:" reward",
  The_interstellar:"The interstellar monster carries a large amount of GWC, all GWC warriors can enter the interstellar to eliminate this monster, and get the dropped GWC reward",
  The_interstellar_lp:"The interstellar monster carries a large amount of GWC-USDT LP, all GWC-USDT LP warriors can enter the interstellar to eliminate this monster and get the dropped GWC-USDT LP reward",
  BNB_monsters:"BNB monsters escape to the wormhole, all BNB warriors can participate in the exploration of the wormhole, destroy BNB monsters to get BNB",
  BNB_monsters_big:"BNB monsters fled to the wormhole, all BNB warriors can participate in the exploration of the wormhole, destroy the BNB monster elite to get a lot of BNB",
  A_lang_number:"A large number of unknown biological monsters have appeared on U star recently, and the alliance issued a reward order to enter U star by spaceship to eliminate biological monsters and get USDT rewards",
  Et_monsters:"USDT monsters invaded planet H, and the intelligent civilization of the planet suffered heavy losses. The USDT alliance summoned to eliminate USDT monsters and carry a large amount of USDT",
  monster_profile:"monster profile",
  More_elements:"more elements more exploration",
  Gain_power:"gain power over everything",
  Explore_all:"Explore all unknown areas",
  The_interstellar_s:"The interstellar monster carries a small amount of GWC, and all GWC warriors can enter the interstellar to eliminate this monster and get the dropped GWC reward",
  The_interstellar_s_lp:"The interstellar monster carries a small amount of GWC-USDT LP, and all GWC-USDT LP warriors can enter the interstellar to eliminate this monster and get the dropped GWC-USDT LP reward",
  Brand_new_game:"Brand new game update",
  Con_con_con:"Continuous confrontation, continuous improvement",
  Query_the_fall:"Query the fall probability of different characters in each round",
  Different_props_in:"Different props in each game affect the probability of the ending query",
  Universe_Exploration:"Universe Exploration Wealth Code",
  More_games_look_forward:"More games look forward to your joining",
  ranking:"ranking",
  Trade_union:"trade union name",
  union_rewards:"union rewards",
  lifting:"lifting",
  no_yet:"Absent",
  Rewards_are_not:"No reward yet",
  Introduction1:"Introduction",
  join_a_union:"join a union",
  GWC_has_set:"GWC has set up a union mode, so that more players can participate in the union and find a sense of belonging. The establishment of a trade union has a lot of rights and interests. Each trade union has a trade union president, a trade union vice president, 2-5 trade union presidents, and 10-20 trade union elders.",
  There_are_irregular:"There are irregular rewards given to the union every month, and the president and vice president of the union will distribute them reasonably to the development and growth of the union and outstanding union players according to their merits. Players need to pledge a certain amount of GWC to establish a union, and there will be rich rewards waiting for you.",
  There_are_also_massive:"There are also massive benefits for players to join the union",
  Improve_the_game_explosion:"1. Improve the game explosion rate and the success probability of the game",
  The_trade:"2. The trade union helps to make more like-minded friends",
  Priority_to_obtain:"3. Priority to obtain more game information and progress",
  Assist_the_union:"4. Assist the union to get a better ranking and get airdrop rewards",
  Players_join_the_union:"Players join the union requirements",
  players_need_to_pay:"1. The trade union sets the entry threshold by itself, for example, 0-10GWC players need to pay",
  Complete_the_tasks:"2. Complete the tasks related to the trade union",
  Union_introduction:"Union introduction and rules",
  GWC_game_has:"GWC game has set a lot of game props",
  Choosing_different_props:"Choosing different props can increase certain game attributes and add legendary elite and other level props",
  The_purchase_of_each:"The purchase of each prop needs to pay a certain amount of GWC play threshold. Some props have the function of 'guaranteeing capital'! It will not affect the game balance and breaking the game balance will only increase game revenue and reduce game revenue.",
  NFT_characters:"GWC games have a large number of original NFT characters",
  game_attributes:"Choosing different characters can increase certain game attributes",
  characters_are_unique:"Add legendary elite and other level characters, some characters are unique!",

  Buy_a_character:"Buy a character and get a character NFT immediately",
  amount_of_GWC:"The purchase of each character needs to pay a certain amount of GWC",

  Does_not_affect:"Does not affect game balance, and break balance",
  winer:"Winer",
  game2tips:"The space-time portal suddenly opens up ten black holes in the GWC universe, each leading to a different interstellar world. However, these interstellar worlds all face the same problem: monstrous monsters that ravage the entire galaxy, destroying every planet. In order to protect time and space and destroy the monsters, various interstellar forces sent elite warriors to GWC universe. These warriors must work together to defeat the monster. Although the task is very difficult but the task is completed, they will get a big reward. Now, the Time gate is closing, and if these warriors can't destroy all the monsters, the GWC universe will be seriously threatened. Time is pressing, the task is difficult, whether can complete the task?",
  game2tips2:"A random monster appears every five minutes from 0 to 90 black holes",
  game2tips3:"All GWC warriors need to purchase GWC weapons to attack the black hole. If they predict the black hole from which the successful monster will appear and be destroyed, they will receive monster rewards.",
  game2tips4:"Predict 8 times revenue for 1 black hole, 4 times revenue for 2 black holes, and 2 times revenue for 4 black holes",
  game2tips5:"The rules are as follows: ",
  game2Info1:"Open <span>{{num}}</span> door",
  game2Info3:"Until the time and space doors open<span>{{time}}</span>",
  takepartin:"Join Now",
  viewearnings:"View benefits",
  game2history:"Records of Monsters Out of the Gate of Time and Space in History",
  game2historymsg:"No. 1 is the latest monster black hole (Gate of Time and Space), counting 100 black hole monsters in history, and I hope the warriors can win",
  price:"cost:",
  income:"Winning income: {{num}} GWC ({{times}} times)",
  InsufficientBalance:"Insufficient balance",
  numbererror:"Can only choose 1, 2, 4",
  create:"Create a union",
  Game0fix:". When the BOSS is killed,",
  Game1fix:" players died together.",
  Game2fix:" players failed,",
  Game3fix:" players win",
  Guildrating:"Guild star:",
  Star:"Lv",
  Unlock:"Unlock",
  Star0:"Normal",
  GuildName:"Guild name:",
  GuildIntroduction:"Guild Profile:",
  GuildImages:"Guild picture:",
  Costofcreation:"Creation fee:",
  createnow:"Create Now",
  rules1:"Please select union star",
  rules2:"Please enter the union name",
  rules3:"Please enter a union profile",
  rules4:"Please upload a union picture",
  Guildrank:"Guild Rank:",
  GuildMaster:"Guild President:",
  waterflow24h:"24H running water:",
  holdgwc:"Support GWC:",
  Guildpeople:"Guild members",
  search:"search",
  member:"member",
  Hflowwater24GWC:"24H Turnover (GWC)",
  flowwaterGWC:"Total Turnover(GWC)",
  Hflowwater24BTY:"24H Turnover (USDG)",

  flowwaterBTY:"Total Turnover(USDG)",
  invitednumber:"Number of invitees",
  Referrersaddress:"Referrer's address (required)",
  join:"Join Now",
  Referrersaddressempty:"Referrer address cannot be empty",
  linkwallet:"Please connect wallet",
  joinok:"you have joined the union",
  GuildPeopleNumber:"Number of unions:",
  GuildStar:"Star",
  waitgame:"Please wait for the draw",
  edit:"editorial union",
  saveEdit:"Save changes",
  timeeditover:"Guild information can be edited every 30 days",
  success:"success",
  hasCreate:"Already created a union",
  isjoined:"Already have a union",
  between10_2000:"Participation fees can only range from 10 to 2000GWC",
  d:'d',
  h:'h',
  m:"m",
  s:"s",
  lab24gwc:'24H(GWC)',
  lab24bty:'24H(BTY)',
  lab24usdg:'24H(USDG)',
  labmonthusdg:'Month(USDG)',
  labGWc:"Total(GWC)",
  labBty:"Total(BTY)",
  labUsdg:"Total(USDG)",
  Winaprize:"Win a prize",
  noWinaprize:"Fail to win",
  Notdrawn:"Not drawn",
  Number:"Number",
  PayGWC:"Pay GWC",
  Status:"status",
  home:{
    about:{
      content1:'Game 1: Single Currency Game',
      content2:'GWC has established different types of "B0SS" NPCs, each of which represents a different concept and attitude. Players can participate in the game by participating in NPC exploration dungeons and other links, ranging from 10 to 1000 people (each "BOSS" has a fixed number of participants). When the number of participants is reached, the exploration is considered successful, and the drop equipment link is immediately triggered. Game players participating in this session can obtain related digital assets such as Bitcoin, Ethereum, Litecoin, etc. randomly dropped by the "BOSS". The way to participate in the game only needs to pay the exploration copy fee 1U and other related assets. ',
      content3:'Gameplay 2: LP Game will randomly drop 5 people, and each person will get 150% of the number of participating LPs, and there is no drop. The remaining 5 people in the  "equipment" will recei',
      content4:'',
      content5:'Gameplay 3: Union Mode',
      content6:'The game recently added a union mode. All gamers who like GWC can choose a union to join. Each union has a different degree of BUFF bonus, such as increasing NPC explosion rate and participation threshold. Every union organizer will receive the corresponding GWC team reward every month.'
    },
    introduce:{
      item1:{
        title:'Mobile mining',
        content1:'In the vast world of GWC, we have started an all-category mining feast! Whether you are a fanatical fan of digital currency or an explorer of blockchain technology, there are treasures that belong to you here. We provide a variety of mining methods to meet the miners with different needs, making every excavation full of surprises.',
        content2:'What is even more exciting is that we will also carry out airdrop reward activities irregularly! As long as you actively participate in mining, you have the opportunity to obtain generous airdrop rewards, making your mining journey more colorful.',
      },
      item2:{
        title:'About Games',
        content1:'GWC uses the cutting-edge encryption authentication technology and decentralized consensus mechanism to carefully build a complete, distributed and data non-tamperable esports community. Here, users can enjoy an immersive competitive experience without worrying about the fairness and compliance of the competition. At the same time, through the application of DeFi products, users can also obtain rich multiple incomes, realizing the perfect combination of competition and income.',
        content2:'',
      },
      item3:{
        title:'About Tribes',
        content1:'Game guild guild mode, all players who love GWC games can join their own camp to receive different rewards and benefits from the guild! Trade unions have different rights waiting for all players in the game. The union has targeted benefits waiting for you every month.',
      },
    },
    model:{
      title:'ECONOMIC MODEL',
      tips:'PLAY / COLLECT / EARN',
      content:'In the virtual world of GWC. Players not only accumulate rich resources and experience through the elaborately designed game collection strategy, but also show unparalleled tacit understanding and team spirit in team battles. Whether it is a fierce competition or a complex mission challenge, with precise judgment and decisive action, they win and obtain more GWC rewards again and again.',
      item1:{
        no:'ONE',
        content1:'Monthly updates'
      },
      item2:{
        no:'TWO',
        content1:'GWC ecology'
      },
      item3:{
        no:'THREE',
        content1:'rich playing method'
      },
    },
    last:{
      content1:'The technical development team is constantly updating the game links and new game content, massive games waiting for you to come. ln addition, GWC has a large number of benefits regularly dropped into the hands of quality players, such as original and valuable NFT, GWC has the spirit of constant exploration of surprises',
      content2:'',
      content3:'',
      content4:''
    }
  },
  news:{
    homeTitle: "GWC Ecology Update ",
    title:"GWC Full Ecological News ",
    tips:"GWC Latest Announcement",
    button:"edit",
    confirm:"submit edit"
  },
  game3:{
    title:' Phase I total amount of 80 million BTYY',
    clock: 'Countdown',
    days:' days',
    up:' look up ',
    down:' look down ',
    selected:'',
    button:' Click to participate ',
    t1:' You lock the BTYY',
    t2:' Lock BTYY time ',
    t3:' You lock the release of BTYY ',
    t4:' Win GWC total ',
    t5:' Participation Details ',
    d1_title:'GWC guess the blind box value ',
    d1_desc1:'GWC carefully built a number of games of different competitive models, players participate in the guess blind box can increase GWC ecological gameplay at the same time, reduce GWC circulation, increase pledge. At the same time, the user whose BTYY number reaches 100W triggers the NFT function. Get GWC exclusive NFT, NFT massive gameplay waiting for you to explore! ',
    d1_desc2:'GWC limited NFT total 500, with community permissions and props! It is beneficial in ecological application and application props. ',
    d2_title:'GWCNFT',
    d2_desc:' Global limited GWCNFT 500, NFT can in the GWC ecological game board has governance voting rights and props function attributes, jointly build GWC ecological world. In 2024, all apps will be open to Eco-GWCNFT players and the union community. ',
  }

};
export default en;
